import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import { Edit as EditIcon, CheckCircle } from "@mui/icons-material";
import { Columns } from "@homesusa/grid-table";
import { toPhoneFormat } from "core/utils";
import { TruncateText } from "core/components";
import { ContactGrid } from "core/interfaces/showing-time";
import { useHasPermissions } from "../use-has-permissions";

type ShowingTimeContactGridColumn = Columns<ContactGrid>;

export const useGetConctactGridColumns = ({
  onSwitch,
  onEdit
}: {
  onSwitch: ({ row }: { row: ContactGrid }) => void;
  onEdit: ({ row }: { row: ContactGrid }) => void;
}): ShowingTimeContactGridColumn => {
  const canEdit = useHasPermissions();

  const columns: ShowingTimeContactGridColumn = React.useMemo(() => {
    const defaultColumns = [
      {
        Header: "First Name",
        accessor: (data: ContactGrid): string => data.firstName,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      },
      {
        Header: "Last Name",
        accessor: (data: ContactGrid): string => data.lastName,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      },
      {
        Header: "Office Phone",
        accessor: (data: ContactGrid): string => data.officePhone,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={toPhoneFormat(value)} />
        )
      },
      {
        Header: "Mobile Phone",
        accessor: (data: ContactGrid): string => data.mobilePhone,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={toPhoneFormat(value)} />
        )
      },
      {
        Header: "Email",
        accessor: (data: ContactGrid): string => data.email,
        Cell: ({ value }: { value: string }): JSX.Element => (
          <TruncateText text={value} />
        )
      }
    ];

    const actionColumn = {
      Header: "Action",
      accessor: (data: ContactGrid): ContactGrid => data,
      Cell: ({ value }: { value: ContactGrid }): JSX.Element => (
        <div style={{ display: "flex", gap: "3px" }}>
          <Tooltip title="Edit">
            <IconButton onClick={() => onEdit({ row: value })}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Assign or Remove">
            <IconButton
              style={{ color: value.inScope ? "green" : "grey" }}
              disabled={value.isFixed}
              onClick={() =>
                value.isFixed ? undefined : onSwitch({ row: value })
              }
            >
              <CheckCircle />
            </IconButton>
          </Tooltip>
        </div>
      )
    };

    return canEdit ? [...defaultColumns, actionColumn] : defaultColumns;
  }, []);

  return columns;
};
