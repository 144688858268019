import { SxProps } from "@mui/system";

interface Styles {
  root: SxProps;
  tabs: SxProps;
  tab: SxProps;
  loader: SxProps;
}

export const useStyles = (): Styles => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  tabs: {
    borderRadius: "50px",
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
  },
  tab: {
    textTransform: "none",
    padding: "12px 24px",
    "&.Mui-selected": { color: "white", zIndex: 2 }
  },
  loader: {
    mt: 2,
    px: 3
  }
});
