import { FormRules } from "@homesusa/form";
import { latitudeRule, longitudeRule } from "core/rules/common-rules";
import { femaFloodPlainRule, propertyRules } from "../../sections";

export const propertyInfoRules: FormRules = {
  ...propertyRules,
  constructionCompletionDate: {
    requiredToSubmit: true,
    label: "Completion Date",
    tooltip: "Completion date where applicable."
  },
  constructionStartYear: {
    requiredToSubmit: true,
    label: "Year Built",
    maxLength: 4
  },
  updateGeocodes: { label: "Update Geocodes" },
  latitude: latitudeRule,
  longitude: longitudeRule,
  femaFloodPlain: femaFloodPlainRule
};
