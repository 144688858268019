import React from "react";

import { AlertSummaryProvider } from "modules/dashboard/context";
import { AlertType } from "modules/dashboard/enums";
import { AlertGrid } from "./alert-grid.component";

export function RightAlertGrid({
  alertType,
  isSuggestedComps = false
}: {
  alertType: AlertType;
  isSuggestedComps?: boolean;
}): JSX.Element {
  return (
    <AlertSummaryProvider>
      <AlertGrid alertType={alertType} isSuggestedComps={isSuggestedComps} />
    </AlertSummaryProvider>
  );
}
