import React from "react";

import { AuthCompanyContext } from "@homesusa/auth";
import { CommunityGrid, PlanGrid } from "core/interfaces";
import { ProfilesContext } from "./profiles.context";
import { getCommunities } from "core/services/sale-communities.service";
import { getPlans } from "core/services/plans.service";

export const ProfilesProvider = ({
  companyId,
  children
}: {
  children: React.ReactNode;
  companyId: string;
}): JSX.Element => {
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const [communities, setCommunities] = React.useState<Array<CommunityGrid>>();
  const [plans, setPlans] = React.useState<Array<PlanGrid>>();
  const fetchData = (): void => {
    getCommunities(currentMarket)({
      companyId,
      take: 300
    }).then(({ data }) => setCommunities(data ?? []));
    getPlans(currentMarket)({
      companyId,
      take: 300
    }).then(({ data }) => setPlans(data ?? []));
  };

  React.useEffect(() => {
    fetchData();
  }, [companyId]);

  return (
    <ProfilesContext.Provider
      value={{ communities, plans, refreshProfiles: fetchData }}
    >
      {children}
    </ProfilesContext.Provider>
  );
};
