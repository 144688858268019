import { FormRules, zipCodeRule } from "@homesusa/form";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import {
  stateRule,
  streetNameRule,
  streetNumberRule,
  subdivisionRules,
  unitNumberRules
} from "core/rules/common-rules";
import { countyRules, aborCityRule } from "../sections";
import {
  StreetType,
  StreetTypeLabel
} from "markets/abor/enums/street-type.enum";

export const addressInfoRules: FormRules = {
  county: {
    ...countyRules,
    requiredToSubmit: true
  },
  subdivision: { ...subdivisionRules, requiredToSubmit: true },
  streetNumber: {
    ...streetNumberRule,
    label: "Street/Box Number",
    notAllowedWhiteSpaces: "both"
  },
  streetName: streetNameRule,
  state: stateRule,
  zipCode: {
    ...zipCodeRule,
    required: true
  },
  city: {
    ...aborCityRule,
    required: true
  },
  unitNumber: unitNumberRules,
  streetType: {
    label: "Street Type",
    options: getOptionsFromEnumAndMap(
      Object.values(StreetType),
      StreetTypeLabel
    ),
    requiredToSubmit: true,
    lastOptions: [StreetType.None],
    noneOption: [StreetType.None]
  }
};
