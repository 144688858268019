import React from "react";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  ShowingRequirements,
  ShowingRequirementsLabel
} from "markets/dfw/enums";
import {
  ShowingContactType,
  ShowingContactTypeLabel
} from "markets/dfw/enums/showing-contact-type.enum";

export const openHousesRules: FormRules = {
  enableOpenHouses: {
    label: "Enable Open House Feature"
  },
  showOpenHousesPending: {
    label: "Show Open House if this listing is PENDING?"
  }
};

export const showingRules: FormRules = {
  realtorContactEmail: {
    label: "Email for Realtors to Contact you",
    tooltip: (
      <p>
        Separate multiple email addresses with a semicolon <br />
        (i.e., john.smith@sample.com;jane.doe@sample.com).
      </p>
    ),
    isMultiple: true
  },
  contactPhone: { label: "Appt. Phone", requiredToSubmit: true },
  occupantPhone: { label: "Alt. Phone" },
  showingInstructions: {
    label: "Showing Instructions",
    requiredToSubmit: true,
    maxLength: 1000,
    regex: '^[^"|\\/]+$',
    regexMessage: 'Not allowed chars "  | \\ /',
    replaceRegex: '["\\/|]+',
    rows: 5
  },
  directions: {
    label: "Public Driving Directions",
    requiredToSubmit: true,
    maxLength: 300,
    regex: '^[^"|\\/]+$',
    regexMessage: 'Not allowed chars "  | \\ /',
    replaceRegex: '["\\/|]+',
    rows: 5
  },
  showingRequirements: {
    label: "Showing",
    requiredToSubmit: true,
    rows: 2,
    options: getOptionsFromEnumAndMap(
      Object.values(ShowingRequirements),
      ShowingRequirementsLabel
    )
  },
  showingContactType: {
    label: "Showing Contact Type",
    requiredToSubmit: false,
    rows: 2,
    options: getOptionsFromEnumAndMap(
      Object.values(ShowingContactType),
      ShowingContactTypeLabel
    )
  }
};
