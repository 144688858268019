import React from "react";
import { Outlet } from "react-router-dom";
import { AuthCompanyContext, useHasRole } from "@homesusa/auth";
import { AppContext } from "@homesusa/layout";
import { useGetCompanyInfo } from "core/hooks";
import { sidebarItems, SidebarItemType } from "core/enums";
import { CompanyProvider } from "core/contexts/company";

export function RouteWrapper(): JSX.Element {
  const { currentCompany } = React.useContext(AuthCompanyContext);
  const { isMlsAdministrator } = useHasRole();
  const [company] = useGetCompanyInfo(currentCompany?.id);
  const { appStateDispatch } = React.useContext(AppContext);

  const filteredSidebarItems = React.useMemo(() => {
    const newItems = { ...sidebarItems };
    if (isMlsAdministrator) {
      return newItems;
    }
    if (company?.settingInfo.hideLeadManager) {
      delete newItems[SidebarItemType.LeadManagement];
    }
    return newItems;
  }, [currentCompany, company]);

  React.useEffect(() => {
    appStateDispatch({
      type: "UpdateSidebarItem",
      payload: filteredSidebarItems
    });
  }, [filteredSidebarItems, appStateDispatch]);

  return (
    <CompanyProvider>
      <Outlet />
    </CompanyProvider>
  );
}
