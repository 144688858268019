import { MlsStatusColor } from "core/enums";

export enum MlsStatus {
  Active = "active",
  ActiveContingent = "activeContingent",
  ActiveKickOut = "activeKickOut",
  ActiveOptionContract = "activeOptionContract",
  Cancelled = "cancelled",
  ComingSoon = "comingSoon",
  Pending = "pending",
  Sold = "sold",
  TempOffMarket = "tempOffMarket",
  Expired = "expired"
}
export const MlsStatusForNewListings = [
  MlsStatus.Active,
  MlsStatus.ActiveContingent,
  MlsStatus.ActiveKickOut,
  MlsStatus.ActiveOptionContract,
  MlsStatus.Pending,
  MlsStatus.ComingSoon,
  MlsStatus.Sold
];

export const MlsStatusLabel = new Map<MlsStatus, string>([
  [MlsStatus.Active, "Active"],
  [MlsStatus.ActiveContingent, "Active Contingent"],
  [MlsStatus.ActiveKickOut, "Active Kick Out"],
  [MlsStatus.ActiveOptionContract, "Active Option Contract"],
  [MlsStatus.Cancelled, "Cancelled"],
  [MlsStatus.ComingSoon, "Coming Soon"],
  [MlsStatus.Pending, "Pending"],
  [MlsStatus.Sold, "Sold"],
  [MlsStatus.TempOffMarket, "Temp Off Market"],
  [MlsStatus.Expired, "Expired"]
]);

export const StatusColorLabel = new Map<MlsStatus, MlsStatusColor>([
  [MlsStatus.Active, MlsStatusColor.Active],
  [MlsStatus.ActiveContingent, MlsStatusColor.Active],
  [MlsStatus.ActiveKickOut, MlsStatusColor.Active],
  [MlsStatus.ActiveOptionContract, MlsStatusColor.Active],
  [MlsStatus.Pending, MlsStatusColor.Pending],
  [MlsStatus.Sold, MlsStatusColor.Sold],
  [MlsStatus.TempOffMarket, MlsStatusColor.Withdrawn],
  [MlsStatus.Cancelled, MlsStatusColor.Canceled],
  [MlsStatus.ComingSoon, MlsStatusColor.Withdrawn],
  [MlsStatus.Expired, MlsStatusColor.Canceled]
]);

export const MlsStatusWithoutStatusFields: MlsStatus[] = [
  MlsStatus.Active,
  MlsStatus.ComingSoon
];
