import { States, StatesLabel } from "core/enums";
import { AddressInfo } from "core/interfaces/listing";
import { toUpperCaseFirstLetter } from "./converters";

interface ListingAddressProps {
  addressInfo: AddressInfo;
  getCityLabel: (city: string) => string;
  excludeStreetTypes?: string[];
}

export const getListingToolbarTitle = ({
  mlsNumber,
  ...addressProps
}: ListingAddressProps & { mlsNumber: string }): string => {
  return (
    getListingAddress(addressProps) +
    (mlsNumber ? ` - [MLS# ${mlsNumber}]` : "")
  );
};

export const getRequestToolbarTitle = ({
  mlsNumber,
  ...addressProps
}: ListingAddressProps & { mlsNumber: string }): string => {
  const mlsNumberParsed = mlsNumber ? `[${mlsNumber}]` : "[ ]";
  return `${mlsNumberParsed} - ${getListingAddress(addressProps)}`;
};

const getListingAddress = ({
  addressInfo,
  getCityLabel,
  excludeStreetTypes
}: ListingAddressProps): string => {
  const address = [
    addressInfo.streetNumber,
    `${addressInfo.streetName}${
      addressInfo.streetType &&
      (!excludeStreetTypes ||
        !excludeStreetTypes.includes(addressInfo.streetType))
        ? ` ${toUpperCaseFirstLetter(addressInfo.streetType)}`
        : ""
    },`,
    addressInfo.unitNumber &&
      `${getCityLabel(addressInfo.unitNumber.toString())},`,
    addressInfo.city && getCityLabel(addressInfo.city),
    addressInfo.state && StatesLabel[addressInfo.state as States],
    addressInfo.zipCode && addressInfo.zipCode
  ];

  return address.filter(Boolean).join(" ");
};
