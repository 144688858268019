import {
  getOptionsFromEnumAndMap,
  getOptionsFromEnumAndRecord
} from "@homesusa/core";
import { FieldRulesProps } from "@homesusa/form";
import {
  ConstructionStage,
  ConstructionStageLabel,
  HousingType,
  HousingTypeLabel,
  LakeName,
  LakeNameLabel,
  LotDescription,
  LotDescriptionLabel,
  LotSize,
  LotSizeLabel,
  LotSizeUnit,
  LotSizeUnitLabel,
  MultiFamilyPropertyType,
  PropertySubType,
  PropertySubTypeLabel,
  PropertyType,
  PropertyTypeLabel,
  ResidentialPropertyType
} from "markets/dfw/enums";

export const constructionStageRules: FieldRulesProps = {
  label: "Construction Stage",
  requiredToSubmit: true,
  options: getOptionsFromEnumAndMap(
    Object.values(ConstructionStage),
    ConstructionStageLabel
  )
};
export const propertyTypeRules: FieldRulesProps = {
  label: "Property Type",
  options: getOptionsFromEnumAndRecord(
    Object.values(PropertyType),
    PropertyTypeLabel
  ),
  requiredToSubmit: true
};
export const propertyFeatures: Record<string, string[]> = {
  [PropertyType.Residencial]: ResidentialPropertyType,
  [PropertyType.ResidencialIncome]: MultiFamilyPropertyType
};
export const propertySubTypeRules: FieldRulesProps = {
  label: "Property Sub Type",
  options: getOptionsFromEnumAndRecord(
    Object.values(PropertySubType),
    PropertySubTypeLabel
  ),
  requiredToSubmit: true
};

export const lotDescriptionRules: FieldRulesProps = {
  label: "Lot Features",
  options: getOptionsFromEnumAndMap(
    Object.values(LotDescription),
    LotDescriptionLabel
  )
};

export const lotSizeAreaRules: Record<string, FieldRulesProps> = {
  lotSizeArea: {
    label: "Lot Size Area",
    maxLength: 16,
    tooltip: "For less than one acre use a decimal - Example .25",
    numberFormat: {
      decimalScale: 4
    }
  },
  lotSizeUnit: {
    label: "Lot Size Unit",
    options: getOptionsFromEnumAndMap(
      Object.values(LotSizeUnit),
      LotSizeUnitLabel
    )
  }
};

export const lotSizeRules: FieldRulesProps = {
  label: "Lot Size",
  options: getOptionsFromEnumAndMap(Object.values(LotSize), LotSizeLabel)
};

export const housingTypeRules: FieldRulesProps = {
  label: "Housing Type",
  requiredToSubmit: true,
  options: getOptionsFromEnumAndMap(
    Object.values(HousingType),
    HousingTypeLabel
  )
};
export const constructionStartYearRules: FieldRulesProps = {
  requiredToSubmit: true,
  label: "Year Built",
  maxLength: 4
};

export const plannedCommunityRules: FieldRulesProps = {
  label: "Master Planned Community Name"
};
export const lakeNameRules: FieldRulesProps = {
  label: "Lake Name",
  options: getOptionsFromEnumAndMap(Object.values(LakeName), LakeNameLabel)
};

export const hasPropertyAttachedRules: FieldRulesProps = {
  label: "Property Attached"
};
