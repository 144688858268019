import React, { Fragment } from "react";
import { Box } from "@mui/material";

import {
  FormContext,
  FormContextActions,
  formContextReducer,
  FormMode
} from "@homesusa/form";
import {
  GridTable,
  GridProvider,
  GridToolbar,
  FetchData,
  Columns
} from "@homesusa/grid-table";

import { AgentFilter } from "../interfaces/filters";
import { ListingAgent, ReportDataset } from "../interfaces";
import { AgentType, HomesUSAType, ListType } from "../enums";
import { ReportsContext } from "../contexts";
import { Filters } from "./filters.component";
import { ReportDates } from "./report-dates.component";

function AgentsReport({
  columns,
  children: filters,
  agentType,
  homesUSAType = HomesUSAType.All
}: {
  columns: Columns<ListingAgent>;
  children?: React.ReactNode;
  agentType: AgentType;
  homesUSAType?: HomesUSAType;
}): JSX.Element {
  const {
    services: { getListingAgents },
    reportFilter,
    setReportFilter
  } = React.useContext(ReportsContext);
  const [formState, formDispatch] = React.useReducer<
    (state: AgentFilter, action: FormContextActions) => AgentFilter
  >(
    formContextReducer,
    Object.assign({ ...reportFilter, agentType, homesUSAType })
  );
  const [data, setData] = React.useState<ReportDataset<ListingAgent>>({
    data: [],
    total: 0,
    agentLastUpdate: undefined,
    officeLastUpdate: undefined,
    listingLastUpdate: undefined
  });

  const fetchData = async ({
    pageSize,
    pageIndex,
    sortBy,
    globalFilter
  }: FetchData): Promise<void> => {
    setReportFilter(formState);
    return getListingAgents({
      ...formState,
      top: pageSize,
      skip: pageIndex,
      agentType: formState.agentType,
      sortBy: sortBy,
      searchBy: globalFilter
    }).then((response) => {
      setData(response);
    });
  };

  const fetchDataForDownloading = async (): Promise<ListingAgent[]> => {
    return getListingAgents({
      ...formState,
      top: data.total,
      skip: 0
    }).then((response) => {
      return response.data;
    });
  };

  return (
    <Fragment>
      <FormContext.Provider
        value={{
          formState: formState,
          formDispatch,
          formMode: FormMode.Update,
          formRules: {},
          otherProps: {}
        }}
      >
        <GridProvider
          options={{
            columns,
            data: data?.data,
            showHeaderTooltip: true
          }}
          totalRows={data?.total}
        >
          <Filters onFetch={fetchData}>
            <Filters.ListType typeAvailable={[ListType.Residential]} />
            <Filters.Price />
            <Filters.Address />
            <Filters.Subdivision />
            {filters}
          </Filters>
          <GridToolbar>
            <GridToolbar.ExportButtons
              onClick={fetchDataForDownloading}
              fileName="selling-agents"
            />
            <GridToolbar.ColumnVisibilityButton />
            <GridToolbar.SearchFilter />
          </GridToolbar>
          <Box mt={2} />
          <GridTable />
          <ReportDates data={data} />
        </GridProvider>
      </FormContext.Provider>
    </Fragment>
  );
}

export { AgentsReport };
