import { MarketCode } from "@homesusa/core";
import { GridResponse } from "@homesusa/grid-table";
import axios from "axios";
import {
  ContactGrid,
  ContactFilters,
  Contact,
  ContactAssign
} from "core/interfaces/showing-time";
import { getApiUrl } from "core/utils/gateway-api";

const apiUrl = (market: MarketCode, path?: string): string =>
  getApiUrl(market, "showing-time-contacts", path);

export const getShowingTimeContactGridData = (
  market: MarketCode,
  params?: ContactFilters
): Promise<GridResponse<ContactGrid>> => {
  return axios
    .get<GridResponse<ContactGrid>>(apiUrl(market), { params })
    .then((response) => response.data);
};

export const getContact = (
  id: string,
  market: MarketCode
): Promise<Contact> => {
  return axios
    .get<Contact>(apiUrl(market, id))
    .then((response) => response.data);
};

export const createContact = (
  market: MarketCode,
  data: Contact
): Promise<string> => {
  return axios
    .post<string>(apiUrl(market), data)
    .then((response) => response.data);
};

export const updateContact = (
  market: MarketCode,
  data: Contact
): Promise<string> => {
  return axios
    .put<string>(apiUrl(market, data.id), data)
    .then((response) => response.data);
};

export const deleteContact = (
  id: string,
  market: MarketCode
): Promise<string> => {
  return axios
    .delete<string>(apiUrl(market, id))
    .then((response) => response.data);
};

export const assignContact = (
  market: MarketCode,
  data: ContactAssign
): Promise<string> => {
  return axios
    .post<string>(apiUrl(market, "assign"), data)
    .then((response) => response.data);
};

export const removeContact = (
  market: MarketCode,
  data: ContactAssign
): Promise<string> => {
  return axios
    .delete<string>(apiUrl(market, "remove"), { data })
    .then((response) => response.data);
};
