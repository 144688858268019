import { FormRules, flattenRules } from "@homesusa/form";
import { showingTimeRules } from "core/rules/showing-time-rules";
import { MlsStatus } from "markets/amarillo/enums";
import { salePropertyRules } from "./sale-property";
import { rulesByStatus } from "./status";
import { amarilloStatusRule } from "../sections";

const rules = {
  mlsStatus: amarilloStatusRule,
  listPrice: {
    label: "List Price",
    requiredToSubmit: true,
    numberFormat: {
      max: 3000000,
      min: 100000,
      prefix: "$",
      thousandSeparator: true
    },
    maxLength: 10
  },
  expirationDate: { label: "Expiration Date", requiredToSubmit: true },
  saleProperty: salePropertyRules,
  showingTime: showingTimeRules
};

export const listingRules: FormRules = flattenRules(rules);

export const listingRulesWithStatus = (mlsStatus: MlsStatus): FormRules =>
  flattenRules({
    ...rules,
    statusFieldsInfo: rulesByStatus.get(mlsStatus)
  });
