import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { CompanyHeader } from "@homesusa/auth";
import { MarketCode } from "@homesusa/core";
import {
  BaseCommunity,
  CommunityCompactCreate,
  CommunityGrid,
  CommunitySavedChanges,
  PhotoRequestGrid
} from "core/interfaces";
import { getGatewayMarket } from "core/utils";
import {
  BaseFilter,
  CommunitySavedChangesFilter
} from "core/interfaces/filters";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

export const getSaleCommunitiesApiUrl = (market: MarketCode): string => {
  const gatewayMarket = getGatewayMarket(market);
  return `${gatewayUrl}/api/${gatewayMarket}/sale-communities`;
};
export const getCommunityHistoryApiUrl = (market: MarketCode): string => {
  const gatewayMarket = getGatewayMarket(market);
  return `${gatewayUrl}/api/${gatewayMarket}/community-history`;
};

export const getCommunities =
  (market: MarketCode) =>
  async ({
    companyId,
    ...params
  }: BaseFilter & {
    companyId?: string;
  }): Promise<GridResponse<CommunityGrid>> => {
    const apiUrl = getSaleCommunitiesApiUrl(market);
    const config: AxiosRequestConfig = {
      params,
      headers: companyId ? { [CompanyHeader]: companyId } : undefined
    };
    return axios
      .get<GridResponse<CommunityGrid>>(apiUrl, config)
      .then((response) => response.data);
  };

export const getCommunity =
  <TCommunity extends BaseCommunity>(market: MarketCode) =>
  async (communityId: string): Promise<TCommunity> => {
    const apiUrl = getSaleCommunitiesApiUrl(market);
    const response = await axios.get<TCommunity>(`${apiUrl}/${communityId}`);
    return response.data;
  };
export const getCommunitySavedChanges =
  (market: MarketCode) =>
  async (
    params: CommunitySavedChangesFilter
  ): Promise<GridResponse<CommunitySavedChanges>> => {
    const apiUrl = getCommunityHistoryApiUrl(market);
    return axios
      .get<GridResponse<CommunitySavedChanges>>(`${apiUrl}`, { params })
      .then((response) => response.data);
  };
export const createCommunity =
  <TCommunityCompactCreate extends CommunityCompactCreate>(
    market: MarketCode
  ) =>
  async (community: TCommunityCompactCreate): Promise<string> => {
    const apiUrl = getSaleCommunitiesApiUrl(market);
    const response = await axios.post<
      TCommunityCompactCreate,
      AxiosResponse<string>
    >(apiUrl, community);
    return response.data;
  };

export const getPhotoRequests =
  (market: MarketCode) =>
  async ({
    entityId,
    ...params
  }: {
    entityId: string;
    status?: string[];
    pageSize?: number;
    startIndex?: number;
  }): Promise<GridResponse<PhotoRequestGrid>> => {
    const apiUrl = getSaleCommunitiesApiUrl(market);
    return axios
      .get<GridResponse<PhotoRequestGrid>>(
        `${apiUrl}/${entityId}/photo-requests`,
        {
          params
        }
      )
      .then((response) => response.data);
  };
