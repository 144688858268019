import { MarketCode } from "@homesusa/core";
import { getGatewayMarket } from "./gateway-market";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

export interface SearchFilters {
  take?: number;
  skip?: number;
  companyId?: string;
  searchBy?: string;
  sortBy?: string;
  isForDownloading?: boolean;
}

export const getApiUrl = (
  market: MarketCode,
  scope: string,
  path: string | undefined = undefined
): string => {
  const gatewayMarket = getGatewayMarket(market);
  return `${gatewayUrl}/api/${gatewayMarket}/${scope}/${path || ""}`;
};
