import { FormRules, zipCodeRule } from "@homesusa/form";
import {
  stateRule,
  streetNameRule,
  streetNumberRule,
  subdivisionRules
} from "core/rules/common-rules";
import { countyRules, saborCityRule } from "../../sections";

export const addressInfoRules: FormRules = {
  county: {
    ...countyRules,
    requiredToSubmit: true
  },
  subdivision: { ...subdivisionRules, requiredToSubmit: true },
  streetNumber: {
    ...streetNumberRule,
    label: "Street/Box Number",
    notAllowedWhiteSpaces: "both"
  },
  streetName: streetNameRule,
  state: stateRule,
  zipCode: {
    ...zipCodeRule,
    required: true
  },
  city: {
    ...saborCityRule,
    required: true
  },
  unitNumber: {
    label: "Unit #",
    requiredToSubmit: false,
    minLength: 1,
    maxLength: 10
  },
  lotNum: {
    requiredToSubmit: true,
    label: "Lot",
    maxLength: 5
  },

  block: {
    requiredToSubmit: true,
    label: "Block",
    maxLength: 5
  }
};
