import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules, zipCodeRule } from "@homesusa/form";
import {
  StreetDirection,
  StreetDirectionLabel,
  StreetType,
  StreetTypeLabel
} from "markets/dfw/enums";
import {
  stateRule,
  streetNameRule,
  streetNumberRule,
  subdivisionRules,
  unitNumberRules
} from "core/rules/common-rules";
import { countyRules, dfwCityRule } from "../../sections";

export const addressInfoRules: FormRules = {
  streetNumber: streetNumberRule,
  streetName: streetNameRule,
  streetType: {
    label: "Street Type",
    options: getOptionsFromEnumAndMap(
      Object.values(StreetType),
      StreetTypeLabel
    )
  },
  streetDirection: {
    label: "Pre-Directions",
    options: getOptionsFromEnumAndMap(
      Object.values(StreetDirection),
      StreetDirectionLabel
    )
  },
  unitNumber: unitNumberRules,
  city: {
    ...dfwCityRule,
    required: true
  },
  state: stateRule,
  zipCode: {
    ...zipCodeRule,
    required: true
  },
  zipCodeVerification: {
    label: "Zip Code Verification"
  },
  county: {
    ...countyRules,
    requiredToSubmit: true
  },
  subdivision: {
    ...subdivisionRules,
    requiredToSubmit: true
  }
};
