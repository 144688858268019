import { FieldRulesProps, FormRules } from "@homesusa/form";
import { subdivisionRules } from "core/rules/common-rules";
import { amarilloCityRule, countyRules } from "../../sections";

export const propertyRules: Record<string, FormRules | FieldRulesProps> = {
  city: {
    ...amarilloCityRule,
    required: false
  },
  subdivision: subdivisionRules,
  county: {
    ...countyRules,
    required: false
  }
};
