export enum AccessMethod {
  Combination = "combination",
  SupraiBox = "supraiBox",
  CodeBox = "codeBox",
  MasterLockBluetooth = "masterLockBluetooth",
  SentryLock = "sentryLock",
  SellerOrTenant = "sellerOrTenant",
  GateGuardDoormanConcierge = "gateGuardDoormanConcierge",
  Keypad = "keypad"
}

export const AccessMethodLabel = new Map<AccessMethod, string>([
  [AccessMethod.Combination, "Combination"],
  [AccessMethod.SupraiBox, "Supra iBox"],
  [AccessMethod.CodeBox, "CodeBox"],
  [AccessMethod.MasterLockBluetooth, "Master Lock Bluetooth"],
  [AccessMethod.SentryLock, "SentriLock"],
  [AccessMethod.SellerOrTenant, "Seller or Tenant"],
  [AccessMethod.GateGuardDoormanConcierge, "Gate Guard, Doorman, or Concierge"],
  [AccessMethod.Keypad, "Keypad"]
]);
