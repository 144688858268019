import React from "react";

import { AlertType, AlertTypeLabel } from "modules/dashboard/enums";
import { RightAlertGrid } from "./right-alert-grid.component";
import { AnimatedContainer } from "@homesusa/core";
import { ActionsTab } from "../../action-tab";
import { Tab } from "modules/dashboard/interfaces";

export const AlertGridTabs = (): JSX.Element => {
  const getLabel = (tab: AlertType): string => AlertTypeLabel.get(tab) ?? tab;

  const tabs: Tab[] = React.useMemo(() => {
    return [
      {
        label: getLabel(AlertType.XmlHomesToListNow),
        node: <RightAlertGrid alertType={AlertType.XmlHomesToListNow} />,
        tabId: AlertType.XmlHomesToListNow
      },
      {
        label: getLabel(AlertType.XmlSuggestedComps),
        node: (
          <RightAlertGrid
            isSuggestedComps
            alertType={AlertType.XmlSuggestedComps}
          />
        ),
        tabId: AlertType.XmlSuggestedComps
      }
    ];
  }, []);

  return (
    <AnimatedContainer>
      <ActionsTab tabs={tabs} hideSectionsTab />
    </AnimatedContainer>
  );
};
