export const SqftToAcres = (sqft?: number): string => {
  if (!sqft) return "0";
  const acres = sqft / 43560;
  return acres.toFixed(2);
};

export const toUpperCaseFirstLetter = (str: string): string =>
  `${str[0].toUpperCase()}${str.slice(1)}`;

export const toPhoneFormat = (str: string): string => {
  if (!/\d{10,12}/g.exec(str)) return str;
  const cod = str.slice(0, 3);
  const head = str.slice(3, 6);
  const tail = str.slice(6);
  return `(${cod}) ${head}-${tail}`;
};
