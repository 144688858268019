import React from "react";

import { CommunityGrid, PlanGrid } from "core/interfaces";

export const ProfilesContext = React.createContext<{
  communities?: CommunityGrid[];
  plans?: PlanGrid[];
  refreshProfiles: () => void;
}>({
  communities: [],
  plans: [],
  refreshProfiles: () => {
    return;
  }
});
