import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { CompanyHeader } from "@homesusa/auth";
import { MarketCode } from "@homesusa/core";
import { PlanCompactCreate, PlanGrid } from "core/interfaces";
import { BaseFilter } from "core/interfaces/filters";
import { getGatewayMarket } from "core/utils";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

export const getPlansApiUrl = (market: MarketCode): string => {
  const gatewayMarket = getGatewayMarket(market);
  return `${gatewayUrl}/api/${gatewayMarket}/plans`;
};

export const getPlans =
  (market: MarketCode) =>
  async ({
    companyId,
    ...params
  }: BaseFilter & {
    companyId?: string;
  }): Promise<GridResponse<PlanGrid>> => {
    const apiUrl = getPlansApiUrl(market);

    const config: AxiosRequestConfig = {
      params,
      headers: companyId ? { [CompanyHeader]: companyId } : undefined
    };
    return axios
      .get<GridResponse<PlanGrid>>(apiUrl, config)
      .then((response) => response.data);
  };

export const createPlan =
  (market: MarketCode) =>
  async (data: PlanCompactCreate): Promise<string> => {
    const apiUrl = getPlansApiUrl(market);
    const response = await axios.post<PlanCompactCreate, AxiosResponse<string>>(
      apiUrl,
      data
    );
    return response.data;
  };
