import React from "react";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import {
  ShowingInstructions,
  ShowingInstructionsLabel
} from "markets/har/enums";

export const openHousesRules: FormRules = {
  enableOpenHouses: {
    label: "Enable Open House Feature"
  },
  showOpenHousesPending: {
    label: "Show Open House if this listing is PENDING?"
  }
};

export const showingRules: FormRules = {
  realtorContactEmail: {
    label: "Email for Realtors to Contact you",
    tooltip: (
      <p>
        Separate multiple email addresses with a semicolon <br />
        (i.e., john.smith@sample.com;jane.doe@sample.com).
      </p>
    ),
    isMultiple: true
  },
  contactPhone: { label: "Appt. Phone", requiredToSubmit: true },
  occupantPhone: { label: "Alt. Phone" },
  showingInstructions: {
    label: "Showing Instructions",
    requiredToSubmit: true,
    rows: 2,
    options: getOptionsFromEnumAndMap(
      Object.values(ShowingInstructions),
      ShowingInstructionsLabel
    )
  },
  directions: {
    label: "Directions",
    requiredToSubmit: true,
    minLength: 25,
    maxLength: 500,
    regex: '^[^"|\\/|%|$|#|*]+$',
    regexMessage: "Not allowed chars % $ # *",
    replaceRegex: '["\\/|]+',
    rows: 5
  }
};
