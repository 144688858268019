import { FormRules, zipCodeRule } from "@homesusa/form";
import { subdivisionRules } from "core/rules/common-rules";
import { ctxCityRule, countyRules, utilitiesRules } from "../../sections";
import {
  constructionStageRules,
  constructionStartYearRules,
  typeCategoryRules,
  yearBuiltSourceRules
} from "../../sections/property-rules";

export const propertyRules: FormRules = {
  typeCategory: typeCategoryRules,
  constructionStage: constructionStageRules,
  constructionStartYear: constructionStartYearRules,
  yearBuiltSource: yearBuiltSourceRules,
  lotDimension: utilitiesRules.lotDimension,
  lotSize: utilitiesRules.lotSize,
  subdivision: subdivisionRules,
  zipCode: zipCodeRule,
  city: {
    ...ctxCityRule,
    required: true
  },
  county: countyRules
};
