import React from "react";

import { useHasXmlService } from "core/hooks";
import { CompanyContext } from "./company.context";

export const CompanyProvider = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const hasXmlService = useHasXmlService();

  return (
    <CompanyContext.Provider value={{ hasXmlService }}>
      {children}
    </CompanyContext.Provider>
  );
};
