export enum AppointmentType {
  GoAndShow = "goAndShow",
  CourtesyCall = "courtesyCall",
  AppointmentRequired = "appointmentRequired"
}

export const AppointmentTypeLabel = new Map<AppointmentType, string>([
  [AppointmentType.GoAndShow, "Go and Show"],
  [AppointmentType.CourtesyCall, "Courtesy Call"],
  [AppointmentType.AppointmentRequired, "Appointment Required"]
]);
