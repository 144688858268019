import React from "react";

import { MarketCode } from "@homesusa/core";
import { FetchData, GridResponse, useFetchData } from "@homesusa/grid-table";
import { ContactGrid } from "core/interfaces/showing-time";
import { getShowingTimeContactGridData } from "core/services/showing-time.service";
import { ContactScope } from "core/enums/showing-time/contact-scope.enum";

export const useGetShowingTimeContactGridData = (
  scope: ContactScope,
  scopeId: string,
  marketCode: MarketCode,
  limitToScope?: boolean,
  companyId?: string,
  reload?: number
): {
  fetchData: (props: FetchData) => Promise<void>;
  fetchDataForDownloading: () => Promise<ContactGrid[]>;
  data: GridResponse<ContactGrid>;
} => {
  const defaultSortBy = "+order";

  const getGridData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
      sortBy,
      globalFilter
    }: FetchData): Promise<GridResponse<ContactGrid>> => {
      return getShowingTimeContactGridData(marketCode, {
        take: pageSize,
        skip: pageIndex,
        searchBy: globalFilter,
        sortBy: sortBy ?? defaultSortBy,
        scope: scope,
        scopeId: scopeId,
        limitToScope: limitToScope ?? true,
        companyId
      });
    },
    [limitToScope, reload]
  );

  return useFetchData<ContactGrid>(getGridData);
};
