export enum AppointmentChangesNotificationOptions {
  ConfirmDeclinedAndCancelCalls = "confirmDeclinedAndCancelCalls",
  DeclinedAndCancelCallsOnly = "declinedAndCancelCallsOnly"
}

export const AppointmentChangesNotificationOptionsLabel = new Map([
  [
    AppointmentChangesNotificationOptions.ConfirmDeclinedAndCancelCalls,
    "Confirm Declined and Cancel Calls"
  ],
  [
    AppointmentChangesNotificationOptions.DeclinedAndCancelCallsOnly,
    "Declined and Cancel Calls Only"
  ]
]);
