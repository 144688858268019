import React from "react";
import { Box } from "@mui/material";
import { AlertGridTabs } from "./alert-grid-tabs.component";

export const RightPanel = (): JSX.Element => {
  return (
    <Box sx={{ height: "600px" }}>
      <AlertGridTabs />
    </Box>
  );
};
