import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FieldRulesProps } from "@homesusa/form";
import {
  ConstructionStage,
  ConstructionStageLabel,
  TypeCategory,
  TypeCategoryLabel,
  YearBuiltSource,
  YearBuiltSourceLabel
} from "markets/ctx/enums";
export const typeCategoryRules: FieldRulesProps = {
  label: "Property Sub Type",
  requiredToSubmit: true,
  options: getOptionsFromEnumAndMap(
    Object.values(TypeCategory),
    TypeCategoryLabel
  )
};
export const constructionStageRules: FieldRulesProps = {
  label: "Construction Status",
  requiredToSubmit: true,
  options: getOptionsFromEnumAndMap(
    Object.values(ConstructionStage),
    ConstructionStageLabel
  )
};
export const constructionStartYearRules: FieldRulesProps = {
  requiredToSubmit: true,
  label: "Year Built",
  maxLength: 4
};
export const yearBuiltSourceRules: FieldRulesProps = {
  label: "Year Built Source",
  requiredToSubmit: true,
  options: getOptionsFromEnumAndMap(
    Object.values(YearBuiltSource),
    YearBuiltSourceLabel
  )
};
