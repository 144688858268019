import { FormRules, zipCodeRule } from "@homesusa/form";
import { harCityRule, countyRules, utilitiesRules } from "../../sections";
import { SubArea, SubAreaLabel } from "markets/har/enums";
import {
  constructionStageRules,
  constructionStartYearRules,
  housingTypeRules,
  propertyTypeRules,
  lotDescriptionRules,
  lotSizeSourceRules,
  mlsAreaRules,
  mapscoGridRules,
  legalSubdivisionRules,
  isPlannedCommunityRules,
  plannedCommunityRules
} from "../../sections/property-rules";
import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { subdivisionRules } from "core/rules/common-rules";

export const propertyRules: FormRules = {
  constructionStage: constructionStageRules,
  constructionStartYear: constructionStartYearRules,
  lotDimension: utilitiesRules.lotDimension,
  lotSize: utilitiesRules.lotSize,
  propertyType: propertyTypeRules,
  housingType: housingTypeRules,
  lotDescription: lotDescriptionRules,
  lotSizeSource: lotSizeSourceRules,
  subdivision: subdivisionRules,
  legalSubdivision: legalSubdivisionRules,
  mapscoGrid: mapscoGridRules,
  mlsArea: mlsAreaRules,
  subArea: {
    label: "Sub Area",
    options: getOptionsFromEnumAndMap(Object.values(SubArea), SubAreaLabel)
  },

  isPlannedCommunity: isPlannedCommunityRules,
  plannedCommunity: plannedCommunityRules,
  zipCode: zipCodeRule,
  city: {
    ...harCityRule,
    required: true
  },
  county: {
    ...countyRules,
    required: false
  },
  isNewConstruction: { label: "New Construction" }
};
