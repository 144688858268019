export enum RoomDescription {
  LivingArea1stFloor = "livingArea1stFloor",
  WineRoom = "wineRoom",
  GuestSuite = "guestSuite",
  GameroomDown = "gameroomDown",
  GarageApartment = "garageApartment",
  FormalLiving = "formalLiving",
  LivingArea3rdFloor = "livingArea3rdFloor",
  Media = "media",
  LivingArea2ndFloor = "livingArea2ndFloor",
  FormalDining = "formalDining",
  LivingDiningCombo = "livingDiningCombo",
  SunRoom = "sunRoom",
  Loft = "loft",
  BreakfastRoom = "breakfastRoom",
  KitchenDiningCombo = "kitchenDiningCombo",
  GuestSuiteWithKitchen = "guestSuiteWithKitchen",
  QuartersGuestHouse = "quartersGuestHouse",
  GameroomUp = "gameroomUp",
  Den = "den",
  OneLivingArea = "oneLivingArea",
  HomeOfficeStudy = "homeOfficeStudy",
  FamilyRoom = "familyRoom",
  UtilityRoomInGarage = "utilityRoomInGarage",
  UtilityRoomInHouse = "utilityRoomInHouse"
}
export const RoomDescriptionLabel = new Map<RoomDescription, string>([
  [RoomDescription.LivingArea1stFloor, "Living Area - 1st Floor"],
  [RoomDescription.WineRoom, "Wine Room"],
  [RoomDescription.GuestSuite, "Guest Suite"],
  [RoomDescription.GameroomDown, "Gameroom Down"],
  [RoomDescription.GarageApartment, "Garage Apartment"],
  [RoomDescription.FormalLiving, "Formal Living"],
  [RoomDescription.LivingArea3rdFloor, "Living Area - 3rd Floor"],
  [RoomDescription.Media, "Media"],
  [RoomDescription.LivingArea2ndFloor, "Living Area - 2nd Floor"],
  [RoomDescription.FormalDining, "Formal Dining"],
  [RoomDescription.LivingDiningCombo, "Living/Dining Combo"],
  [RoomDescription.SunRoom, "Sun Room"],
  [RoomDescription.Loft, "Loft"],
  [RoomDescription.BreakfastRoom, "Breakfast Room"],
  [RoomDescription.KitchenDiningCombo, "Kitchen/Dining Combo"],
  [RoomDescription.GuestSuiteWithKitchen, "Guest Suite w/Kitchen"],
  [RoomDescription.QuartersGuestHouse, "Quarters/Guest House"],
  [RoomDescription.GameroomUp, "Gameroom Up"],
  [RoomDescription.Den, "Den"],
  [RoomDescription.OneLivingArea, "1 Living Area"],
  [RoomDescription.HomeOfficeStudy, "Home Office/Study"],
  [RoomDescription.FamilyRoom, "Family Room"],
  [RoomDescription.UtilityRoomInGarage, "Utility Room In Garage"],
  [RoomDescription.UtilityRoomInHouse, "Utility Room In House"]
]);
