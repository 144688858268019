import { FormRules, zipCodeRule } from "@homesusa/form";
import { subdivisionRules } from "core/rules/common-rules";
import { dfwCityRule, countyRules, utilitiesRules } from "../../sections";
import {
  constructionStageRules,
  constructionStartYearRules,
  housingTypeRules,
  lotDescriptionRules,
  plannedCommunityRules,
  lotSizeAreaRules,
  lakeNameRules,
  hasPropertyAttachedRules,
  lotSizeRules,
  propertySubTypeRules,
  propertyTypeRules
} from "../../sections/property-rules";

export const propertyRules: FormRules = {
  constructionStage: constructionStageRules,
  constructionStartYear: constructionStartYearRules,
  lotDimension: utilitiesRules.lotDimension,
  lotSize: lotSizeRules,
  propertySubType: propertySubTypeRules,
  propertyType: propertyTypeRules,
  housingType: housingTypeRules,
  ...lotSizeAreaRules,
  lotDescription: lotDescriptionRules,
  subdivision: subdivisionRules,
  plannedCommunity: plannedCommunityRules,
  lakeName: lakeNameRules,
  hasPropertyAttached: hasPropertyAttachedRules,
  zipCode: zipCodeRule,
  city: {
    ...dfwCityRule,
    required: false
  },
  county: {
    ...countyRules,
    required: false
  }
};
