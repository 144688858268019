import {
  AborCities,
  AborCitiesLabel,
  getOptionsFromEnumAndRecord
} from "@homesusa/core";
import { FieldRulesProps } from "@homesusa/form";
import { County, CountyLabel } from "markets/abor/enums/county.enum";

export const getAborCityLabel = (city: string | AborCities): string =>
  AborCitiesLabel[city as AborCities] ?? city;

export const aborCityRule: FieldRulesProps = {
  label: "City",
  lastOptions: [AborCities.Other],
  options: getOptionsFromEnumAndRecord(
    Object.values(AborCities),
    AborCitiesLabel
  )
};

export const countyRules: FieldRulesProps = {
  label: "County",
  lastOptions: [County.Other],
  options: getOptionsFromEnumAndRecord(Object.values(County), CountyLabel),
  required: true
};
