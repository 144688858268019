export enum IntermediateSchools {
  AlmaMartinez = "almaMartinez",
  Alvarado = "alvarado",
  Argyle = "argyle",
  Arthur = "arthur",
  AsaELowJr = "asaELowJr",
  Athens = "athens",
  Bailey = "bailey",
  Bakerkoonc = "bakerkoonc",
  BarbaraJordan = "barbaraJordan",
  Bastrop = "bastrop",
  BearCreek = "bearCreek",
  Beltline = "beltline",
  Belton = "belton",
  Blair = "blair",
  Blueridge = "blueridge",
  Borger = "borger",
  Bowen = "bowen",
  Bowie = "bowie",
  Boyd = "boyd",
  Brandenbur = "brandenbur",
  Brawner = "brawner",
  Bridgeport = "bridgeport",
  Brookhaven = "brookhaven",
  Brownsboro = "brownsboro",
  Brownwood = "brownwood",
  BrucevilleEddy = "brucevilleEddy",
  Bullard = "bullard",
  Butler = "butler",
  Caldwell = "caldwell",
  Canton = "canton",
  Canyon = "canyon",
  Caraway = "caraway",
  Carroll = "carroll",
  Cedarcreek = "cedarcreek",
  Center = "center",
  Chacon = "chacon",
  Chandler = "chandler",
  Chinasprng = "chinasprng",
  Chisholmtr = "chisholmtr",
  Clearcreek = "clearcreek",
  Clifton = "clifton",
  Clyde = "clyde",
  Cobb = "cobb",
  Coldspring = "coldspring",
  Collinsville = "collinsville",
  Connally = "connally",
  Corprew = "corprew",
  Crandall = "crandall",
  Crosstimbe = "crosstimbe",
  Crowley = "crowley",
  Crowley9ThGrade = "crowley9ThGrade",
  Cuero = "cuero",
  CypressGrove = "cypressGrove",
  DanPowell = "danPowell",
  Daniel = "daniel",
  Davis = "davis",
  Decatur = "decatur",
  DellaIcenhower = "dellaIcenhower",
  Devine = "devine",
  Dillingham = "dillingham",
  Dodd = "dodd",
  Douglas = "douglas",
  DrAlDraper = "drAlDraper",
  Drane = "drane",
  Dunbar = "dunbar",
  Durham = "durham",
  Eastside = "eastside",
  Edgewood = "edgewood",
  ElaineSSchlather = "elaineSSchlather",
  Ennis = "ennis",
  Eubanks = "eubanks",
  Eustace = "eustace",
  Farmersville = "farmersville",
  Ferris = "ferris",
  Flourbluff = "flourbluff",
  FlowerMound = "flowerMound",
  Freeport = "freeport",
  Gatesville = "gatesville",
  Gilbert = "gilbert",
  Gilmer = "gilmer",
  GlenRose = "glenRose",
  Godley = "godley",
  Goliad = "goliad",
  Grandsalin = "grandsalin",
  Greenways = "greenways",
  Hallsville = "hallsville",
  HannaSprings = "hannaSprings",
  Hardin = "hardin",
  Harmony = "harmony",
  Harrison = "harrison",
  HarryHerndon = "harryHerndon",
  Hebron = "hebron",
  Hodgkins = "hodgkins",
  Howe = "howe",
  Huddleston = "huddleston",
  Huntsville = "huntsville",
  Icenhower = "icenhower",
  Intermedia = "intermedia",
  Jefferson = "jefferson",
  Joshua = "joshua",
  Kemp = "kemp",
  Kilgore = "kilgore",
  Kissam = "kissam",
  Kleb = "kleb",
  LaGrange = "laGrange",
  LaVega = "laVega",
  Lakedallas = "lakedallas",
  LauraIngallsWilder = "lauraIngallsWilder",
  Lee = "lee",
  Leonard = "leonard",
  Libertyhil = "libertyhil",
  Livingston = "livingston",
  Losoya = "losoya",
  Mabank = "mabank",
  Madisonville = "madisonville",
  Marcus = "marcus",
  MaryHarris = "maryHarris",
  MaryLillard = "maryLillard",
  Mcanally = "mcanally",
  Mcculloch = "mcculloch",
  Mcdaniel = "mcdaniel",
  Mclean6Th = "mclean6Th",
  Medinavall = "medinavall",
  MelissaRidge = "melissaRidge",
  Memorial = "memorial",
  Merkel = "merkel",
  Midway = "midway",
  Montgomery = "montgomery",
  Moses = "moses",
  Moss = "moss",
  Mountainva = "mountainva",
  Mtvernon = "mtvernon",
  NaHowry = "naHowry",
  Nash = "nash",
  NellHillRhea = "nellHillRhea",
  NewWaverly = "newWaverly",
  Nichols = "nichols",
  OBryant = "oBryant",
  Oakcrest = "oakcrest",
  Oakwood = "oakwood",
  Orr = "orr",
  Ortiz = "ortiz",
  Palmer = "palmer",
  Paradise = "paradise",
  Parkwood = "parkwood",
  Patton = "patton",
  PilotPoint = "pilotPoint",
  Pinetree = "pinetree",
  Pittsburg = "pittsburg",
  RedOak = "redOak",
  Rhodes = "rhodes",
  RioHondo = "rioHondo",
  Robinson = "robinson",
  Rosebud = "rosebud",
  Rowe = "rowe",
  Rudd = "rudd",
  Salado = "salado",
  Sheffield = "sheffield",
  Sheldon = "sheldon",
  Shepard = "shepard",
  Shepherd = "shepherd",
  Simms = "simms",
  SloanCreek = "sloanCreek",
  Smith = "smith",
  Somerset = "somerset",
  Sonora = "sonora",
  Southkellr = "southkellr",
  Springhill = "springhill",
  Springtown = "springtown",
  Strickland = "strickland",
  Sunnyvale = "sunnyvale",
  Sweetwater = "sweetwater",
  Tannahill = "tannahill",
  Teague = "teague",
  ThomasJefferson = "thomasJefferson",
  Tidehaven = "tidehaven",
  Timberview = "timberview",
  TrinityMeadows = "trinityMeadows",
  Tulosomidw = "tulosomidw",
  Van = "van",
  VanAlstyne = "vanAlstyne",
  Walker = "walker",
  Wedgwood = "wedgwood",
  Weldon = "weldon",
  West = "west",
  Whiteoak = "whiteoak",
  Whitney = "whitney",
  Wilkerson = "wilkerson",
  WillowVista = "willowVista",
  Willspoint = "willspoint",
  Wilson = "wilson",
  Wood = "wood",
  Woodville = "woodville",
  Woolls = "woolls",
  WylieWest = "wylieWest",
  Yoakum = "yoakum"
}

export const IntermediateSchoolLabel: Record<IntermediateSchools, string> = {
  [IntermediateSchools.AlmaMartinez]: "Alma Martinez",
  [IntermediateSchools.Alvarado]: "Alvarado",
  [IntermediateSchools.Argyle]: "Argyle",
  [IntermediateSchools.Arthur]: "Arthur",
  [IntermediateSchools.AsaELowJr]: "Asa E. Low Jr.",
  [IntermediateSchools.Athens]: "Athens",
  [IntermediateSchools.Bailey]: "Bailey",
  [IntermediateSchools.Bakerkoonc]: "Bakerkoonc",
  [IntermediateSchools.BarbaraJordan]: "Barbara Jordan",
  [IntermediateSchools.Bastrop]: "Bastrop",
  [IntermediateSchools.BearCreek]: "Bear Creek",
  [IntermediateSchools.Beltline]: "Beltline",
  [IntermediateSchools.Belton]: "Belton",
  [IntermediateSchools.Blair]: "Blair",
  [IntermediateSchools.Blueridge]: "Blueridge",
  [IntermediateSchools.Borger]: "Borger",
  [IntermediateSchools.Bowen]: "Bowen",
  [IntermediateSchools.Bowie]: "Bowie",
  [IntermediateSchools.Boyd]: "Boyd",
  [IntermediateSchools.Brandenbur]: "Brandenbur",
  [IntermediateSchools.Brawner]: "Brawner",
  [IntermediateSchools.Bridgeport]: "Bridgeport",
  [IntermediateSchools.Brookhaven]: "Brookhaven",
  [IntermediateSchools.Brownsboro]: "Brownsboro",
  [IntermediateSchools.Brownwood]: "Brownwood",
  [IntermediateSchools.BrucevilleEddy]: "Bruceville-Eddy",
  [IntermediateSchools.Bullard]: "Bullard",
  [IntermediateSchools.Butler]: "Butler",
  [IntermediateSchools.Caldwell]: "Caldwell",
  [IntermediateSchools.Canton]: "Canton",
  [IntermediateSchools.Canyon]: "Canyon",
  [IntermediateSchools.Caraway]: "Caraway",
  [IntermediateSchools.Carroll]: "Carroll",
  [IntermediateSchools.Cedarcreek]: "Cedarcreek",
  [IntermediateSchools.Center]: "Center",
  [IntermediateSchools.Chacon]: "Chacon",
  [IntermediateSchools.Chandler]: "Chandler",
  [IntermediateSchools.Chinasprng]: "Chinasprng",
  [IntermediateSchools.Chisholmtr]: "Chisholmtr",
  [IntermediateSchools.Clearcreek]: "Clearcreek",
  [IntermediateSchools.Clifton]: "Clifton",
  [IntermediateSchools.Clyde]: "Clyde",
  [IntermediateSchools.Cobb]: "Cobb",
  [IntermediateSchools.Coldspring]: "Coldspring",
  [IntermediateSchools.Collinsville]: "Collinsville",
  [IntermediateSchools.Connally]: "Connally",
  [IntermediateSchools.Corprew]: "Corprew",
  [IntermediateSchools.Crandall]: "Crandall",
  [IntermediateSchools.Crosstimbe]: "Crosstimbe",
  [IntermediateSchools.Crowley]: "Crowley",
  [IntermediateSchools.Crowley9ThGrade]: "Crowley 9Th Grade",
  [IntermediateSchools.Cuero]: "Cuero",
  [IntermediateSchools.CypressGrove]: "Cypress Grove",
  [IntermediateSchools.DanPowell]: "Dan Powell",
  [IntermediateSchools.Daniel]: "Daniel",
  [IntermediateSchools.Davis]: "Davis",
  [IntermediateSchools.Decatur]: "Decatur",
  [IntermediateSchools.DellaIcenhower]: "Della Icenhower",
  [IntermediateSchools.Devine]: "Devine",
  [IntermediateSchools.Dillingham]: "Dillingham",
  [IntermediateSchools.Dodd]: "Dodd",
  [IntermediateSchools.Douglas]: "Douglas",
  [IntermediateSchools.DrAlDraper]: "Dr. Al Draper",
  [IntermediateSchools.Drane]: "Drane",
  [IntermediateSchools.Dunbar]: "Dunbar",
  [IntermediateSchools.Durham]: "Durham",
  [IntermediateSchools.Eastside]: "Eastside",
  [IntermediateSchools.Edgewood]: "Edgewood",
  [IntermediateSchools.ElaineSSchlather]: "Elaine S Schlather",
  [IntermediateSchools.Ennis]: "Ennis",
  [IntermediateSchools.Eubanks]: "Eubanks",
  [IntermediateSchools.Eustace]: "Eustace",
  [IntermediateSchools.Farmersville]: "Farmersville",
  [IntermediateSchools.Ferris]: "Ferris",
  [IntermediateSchools.Flourbluff]: "Flourbluff",
  [IntermediateSchools.FlowerMound]: "Flower Mound",
  [IntermediateSchools.Freeport]: "Freeport",
  [IntermediateSchools.Gatesville]: "Gatesville",
  [IntermediateSchools.Gilbert]: "Gilbert",
  [IntermediateSchools.Gilmer]: "Gilmer",
  [IntermediateSchools.GlenRose]: "Glen Rose",
  [IntermediateSchools.Godley]: "Godley",
  [IntermediateSchools.Goliad]: "Goliad",
  [IntermediateSchools.Grandsalin]: "Grandsalin",
  [IntermediateSchools.Greenways]: "Greenways",
  [IntermediateSchools.Hallsville]: "Hallsville",
  [IntermediateSchools.HannaSprings]: "Hanna Springs",
  [IntermediateSchools.Hardin]: "Hardin",
  [IntermediateSchools.Harmony]: "Harmony",
  [IntermediateSchools.Harrison]: "Harrison",
  [IntermediateSchools.HarryHerndon]: "Harry Herndon",
  [IntermediateSchools.Hebron]: "Hebron",
  [IntermediateSchools.Hodgkins]: "Hodgkins",
  [IntermediateSchools.Howe]: "Howe",
  [IntermediateSchools.Huddleston]: "Huddleston",
  [IntermediateSchools.Huntsville]: "Huntsville",
  [IntermediateSchools.Icenhower]: "Icenhower",
  [IntermediateSchools.Intermedia]: "Intermedia",
  [IntermediateSchools.Jefferson]: "Jefferson",
  [IntermediateSchools.Joshua]: "Joshua",
  [IntermediateSchools.Kemp]: "Kemp",
  [IntermediateSchools.Kilgore]: "Kilgore",
  [IntermediateSchools.Kissam]: "Kissam",
  [IntermediateSchools.Kleb]: "Kleb",
  [IntermediateSchools.LaGrange]: "La Grange",
  [IntermediateSchools.LaVega]: "La Vega",
  [IntermediateSchools.Lakedallas]: "Lakedallas",
  [IntermediateSchools.LauraIngallsWilder]: "Laura Ingalls Wilder",
  [IntermediateSchools.Lee]: "Lee",
  [IntermediateSchools.Leonard]: "Leonard",
  [IntermediateSchools.Libertyhil]: "Libertyhil",
  [IntermediateSchools.Livingston]: "Livingston",
  [IntermediateSchools.Losoya]: "Losoya",
  [IntermediateSchools.Mabank]: "Mabank",
  [IntermediateSchools.Madisonville]: "Madisonville",
  [IntermediateSchools.Marcus]: "Marcus",
  [IntermediateSchools.MaryHarris]: "Mary Harris",
  [IntermediateSchools.MaryLillard]: "Mary Lillard",
  [IntermediateSchools.Mcanally]: "Mcanally",
  [IntermediateSchools.Mcculloch]: "Mcculloch",
  [IntermediateSchools.Mcdaniel]: "Mcdaniel",
  [IntermediateSchools.Mclean6Th]: "Mclean 6Th",
  [IntermediateSchools.Medinavall]: "Medinavall",
  [IntermediateSchools.MelissaRidge]: "Melissa Ridge",
  [IntermediateSchools.Memorial]: "Memorial",
  [IntermediateSchools.Merkel]: "Merkel",
  [IntermediateSchools.Midway]: "Midway",
  [IntermediateSchools.Montgomery]: "Montgomery",
  [IntermediateSchools.Moses]: "Moses",
  [IntermediateSchools.Moss]: "Moss",
  [IntermediateSchools.Mountainva]: "Mountainva",
  [IntermediateSchools.Mtvernon]: "Mtvernon",
  [IntermediateSchools.NaHowry]: "Na Howry",
  [IntermediateSchools.Nash]: "Nash",
  [IntermediateSchools.NellHillRhea]: "Nell Hill Rhea",
  [IntermediateSchools.NewWaverly]: "New Waverly",
  [IntermediateSchools.Nichols]: "Nichols",
  [IntermediateSchools.OBryant]: "O Bryant",
  [IntermediateSchools.Oakcrest]: "Oakcrest",
  [IntermediateSchools.Oakwood]: "Oakwood",
  [IntermediateSchools.Orr]: "Orr",
  [IntermediateSchools.Ortiz]: "Ortiz",
  [IntermediateSchools.Palmer]: "Palmer",
  [IntermediateSchools.Paradise]: "Paradise",
  [IntermediateSchools.Parkwood]: "Parkwood",
  [IntermediateSchools.Patton]: "Patton",
  [IntermediateSchools.PilotPoint]: "Pilot Point",
  [IntermediateSchools.Pinetree]: "Pinetree",
  [IntermediateSchools.Pittsburg]: "Pittsburg",
  [IntermediateSchools.RedOak]: "Red Oak",
  [IntermediateSchools.Rhodes]: "Rhodes",
  [IntermediateSchools.RioHondo]: "Rio Hondo",
  [IntermediateSchools.Robinson]: "Robinson",
  [IntermediateSchools.Rosebud]: "Rosebud",
  [IntermediateSchools.Rowe]: "Rowe",
  [IntermediateSchools.Rudd]: "Rudd",
  [IntermediateSchools.Salado]: "Salado",
  [IntermediateSchools.Sheffield]: "Sheffield",
  [IntermediateSchools.Sheldon]: "Sheldon",
  [IntermediateSchools.Shepard]: "Shepard",
  [IntermediateSchools.Shepherd]: "Shepherd",
  [IntermediateSchools.Simms]: "Simms",
  [IntermediateSchools.SloanCreek]: "Sloan Creek",
  [IntermediateSchools.Smith]: "Smith",
  [IntermediateSchools.Somerset]: "Somerset",
  [IntermediateSchools.Sonora]: "Sonora",
  [IntermediateSchools.Southkellr]: "Southkellr",
  [IntermediateSchools.Springhill]: "Springhill",
  [IntermediateSchools.Springtown]: "Springtown",
  [IntermediateSchools.Strickland]: "Strickland",
  [IntermediateSchools.Sunnyvale]: "Sunnyvale",
  [IntermediateSchools.Sweetwater]: "Sweetwater",
  [IntermediateSchools.Tannahill]: "Tannahill",
  [IntermediateSchools.Teague]: "Teague",
  [IntermediateSchools.ThomasJefferson]: "Thomas Jefferson",
  [IntermediateSchools.Tidehaven]: "Tidehaven",
  [IntermediateSchools.Timberview]: "Timberview",
  [IntermediateSchools.TrinityMeadows]: "Trinity Meadows",
  [IntermediateSchools.Tulosomidw]: "Tulosomidw",
  [IntermediateSchools.Van]: "Van",
  [IntermediateSchools.VanAlstyne]: "Van Alstyne",
  [IntermediateSchools.Walker]: "Walker",
  [IntermediateSchools.Wedgwood]: "Wedgwood",
  [IntermediateSchools.Weldon]: "Weldon",
  [IntermediateSchools.West]: "West",
  [IntermediateSchools.Whiteoak]: "Whiteoak",
  [IntermediateSchools.Whitney]: "Whitney",
  [IntermediateSchools.Wilkerson]: "Wilkerson",
  [IntermediateSchools.WillowVista]: "Willow Vista",
  [IntermediateSchools.Willspoint]: "Willspoint",
  [IntermediateSchools.Wilson]: "Wilson",
  [IntermediateSchools.Wood]: "Wood",
  [IntermediateSchools.Woodville]: "Woodville",
  [IntermediateSchools.Woolls]: "Woolls",
  [IntermediateSchools.WylieWest]: "Wylie West",
  [IntermediateSchools.Yoakum]: "Yoakum"
};
