import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FieldRulesProps, FormRules } from "@homesusa/form";
import {
  HoaRequirement,
  HoaRequirementLabel,
  HoaTerm,
  HoaTermLabel,
  HOAFeeIncludes,
  HOAFeeIncludesLabel,
  ProposedTerms,
  ProposedTermsLabel
} from "markets/ctx/enums";
import { Exemptions, ExemptionsLabel } from "markets/ctx/enums/exemptions.enum";

const moneyRule = (label: string): FieldRulesProps => ({
  label,
  numberFormat: {
    prefix: "$",
    thousandSeparator: true
  }
});

export const buyersAgentCommissionRules: FormRules = {
  buyersAgentCommission: {
    label: "Buyer Agency Compensation",
    requiredToSubmit: true,
    numberFormat: {
      decimalScale: 2
    }
  },
  readableBuyersAgentCommission: { label: "Buyer Agency Compensation" },
  buyersAgentCommissionType: { label: "Buyer Agency Compensation Type" }
};

export const financialRules: FormRules = {
  taxRate: {
    label: "Tax Rate",
    requiredToSubmit: true,
    maxLength: 14,
    numberFormat: {
      min: 0,
      thousandSeparator: true,
      decimalScale: 12
    }
  },
  titleCompany: {
    label: "Preferred Title Co",
    requiredToSubmit: false,
    maxLength: 45
  },

  hoaRequirement: {
    label: "HOA Requirement",
    requiredToSubmit: true,
    lastOptions: [HoaRequirement.None],
    options: getOptionsFromEnumAndMap(
      Object.values(HoaRequirement),
      HoaRequirementLabel
    )
  },
  hoaName: { label: "HOA Name" },
  hoaAmount: moneyRule("HOA Amount"),
  hoaTerm: {
    label: "HOA Term",
    options: getOptionsFromEnumAndMap(Object.values(HoaTerm), HoaTermLabel)
  },
  hoaMgmtCo: { label: "HOA Mgmt Co" },
  hoaPhone: { label: "HOA Phone #" },
  hoaWebsite: {
    label: "HOA Website",
    maxLength: 500,
    regex:
      "^(?:(?:(?:https?):)?\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z0-9\\u00a1-\\uffff][a-z0-9\\u00a1-\\uffff_-]{0,500})?[a-z0-9\\u00a1-\\uffff]\\.)+(?:[a-z\\u00a1-\\uffff]{2,}))(?::\\d{2,5})?(?:[/?#]\\S*)?$",
    regexMessage: "Invalid Website. Use https:// or http://"
  },
  hoaTransferFeeAmount: moneyRule("HOA Transfer Fee $"),
  hoaFeeIncludes: {
    label: "HOA Fee Includes",
    lastOptions: [HOAFeeIncludes.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(HOAFeeIncludes),
      HOAFeeIncludesLabel
    )
  },
  taxYear: {
    label: "Tax Year",
    maxLength: 4,
    requiredToSubmit: true
  },
  proposedTerms: {
    label: "Acceptable Financing",
    requiredToSubmit: true,
    lastOptions: [ProposedTerms.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(ProposedTerms),
      ProposedTermsLabel
    ),
    rows: 2
  },
  exemptions: {
    label: "Exemptions",
    lastOptions: [Exemptions.None, Exemptions.OtherSeeRemarks],
    options: getOptionsFromEnumAndMap(
      Object.values(Exemptions),
      ExemptionsLabel
    ),
    noneOption: [Exemptions.None],
    rows: 2
  }
};
