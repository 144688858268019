import { MarketCode } from "@homesusa/core";
import { FieldRulesProps, FormRules } from "@homesusa/form";
import { listingRules as saborRules } from "./sabor/rules";
import { listingRules as ctxRules } from "./ctx/rules";
import { listingRules as dfwRules } from "./dfw/rules";
import { saleListingRules as aborRules } from "./abor/rules";
import { listingRules as harRules } from "./har/rules";
import { listingRules as amarilloRules } from "./amarillo/rules";
import {
  MarketStatusToHusaStatus as MarketStatusToSaborStatus,
  MlsStatus as MlsStatusSabor
} from "./sabor/enums";
import { MlsStatus as MlsStatusCtx } from "./ctx/enums";
import { MlsStatus as MlsStatusHar } from "./har/enums";
import { MlsStatus as MlsStatusAbor } from "./abor/enums";
import { MlsStatus as MlsStatusDfw } from "./dfw/enums";
import { MlsStatus as MlsStatusAmarillo } from "./amarillo/enums";
import {
  saborCityRule,
  saborStatusRule,
  saborOccupancyRule
} from "./sabor/rules/sections";
import { ctxCityRule, ctxStatusRule } from "./ctx/rules/sections";
import { aborCityRule, aborStatusRule } from "./abor/rules/sections";
import { harCityRule, harStatusRule } from "./har/rules/sections";
import { dfwCityRule, dfwStatusRule } from "./dfw/rules/sections";
import {
  amarilloCityRule,
  amarilloStatusRule
} from "./amarillo/rules/sections";

export const getMarketRules = (
  market: MarketCode
): {
  formFields: FormRules;
} => {
  switch (market) {
    case MarketCode.Sabor:
      return { formFields: saborRules };
    case MarketCode.CTX:
      return { formFields: ctxRules };
    case MarketCode.Austin:
      return { formFields: aborRules };
    case MarketCode.Houston:
      return { formFields: harRules };
    case MarketCode.DFW:
      return { formFields: dfwRules };
    case MarketCode.Amarillo:
      return { formFields: amarilloRules };
    default:
      return { formFields: {} };
  }
};

export const getMarketCityRule = (market: MarketCode): FieldRulesProps => {
  switch (market) {
    case MarketCode.CTX:
      return ctxCityRule;
    case MarketCode.Austin:
      return aborCityRule;
    case MarketCode.Houston:
      return harCityRule;
    case MarketCode.DFW:
      return dfwCityRule;
    case MarketCode.Amarillo:
      return amarilloCityRule;
    case MarketCode.Sabor:
    default:
      return saborCityRule;
  }
};

export const getMarketStatusRule = (market: MarketCode): FieldRulesProps => {
  switch (market) {
    case MarketCode.CTX:
      return ctxStatusRule;
    case MarketCode.Austin:
      return aborStatusRule;
    case MarketCode.Houston:
      return harStatusRule;
    case MarketCode.DFW:
      return dfwStatusRule;
    case MarketCode.Amarillo:
      return amarilloStatusRule;
    case MarketCode.Sabor:
    default:
      return saborStatusRule;
  }
};

export const getMarketOccupancyRule = (
  market: MarketCode
): FieldRulesProps | undefined => {
  switch (market) {
    case MarketCode.Sabor:
      return saborOccupancyRule;
    case MarketCode.CTX:
    default:
      return undefined;
  }
};

export const getActiveAndPendingStatus = (market: MarketCode): string[] => {
  switch (market) {
    case MarketCode.Sabor:
      return [
        MlsStatusSabor.Active,
        MlsStatusSabor.Pending,
        MlsStatusSabor.ActiveOption,
        MlsStatusSabor.ActiveRFR,
        MlsStatusSabor.BackOnMarket,
        MlsStatusSabor.PendingSB,
        MlsStatusSabor.PriceChange
      ];
    case MarketCode.CTX:
      return [
        MlsStatusCtx.Active,
        MlsStatusCtx.ActiveUnderContract,
        MlsStatusCtx.Pending
      ];
    case MarketCode.Austin:
      return [
        MlsStatusAbor.Active,
        MlsStatusAbor.Pending,
        MlsStatusAbor.ActiveUnderContract
      ];
    case MarketCode.Houston:
      return [
        MlsStatusHar.Active,
        MlsStatusHar.Pending,
        MlsStatusHar.PendingContinueToShow,
        MlsStatusHar.OptionPending
      ];
    case MarketCode.DFW:
      return [
        MlsStatusDfw.Active,
        MlsStatusDfw.ActiveContingent,
        MlsStatusDfw.ActiveKickOut,
        MlsStatusDfw.ActiveOptionContract,
        MlsStatusDfw.Pending
      ];
    case MarketCode.Amarillo:
      return [
        MlsStatusAmarillo.Active,
        MlsStatusAmarillo.UnderContractWContingency,
        MlsStatusAmarillo.Pending
      ];
    default:
      return [];
  }
};

export const marketStatusToHusaStatus = (
  market: MarketCode,
  status: string
): string => {
  switch (market) {
    case MarketCode.CTX:
      return status;
    case MarketCode.Sabor:
      return MarketStatusToSaborStatus.get(status) ?? status;
    default:
      return status;
  }
};

export const isSold = (market: MarketCode, mlsStatus: string): boolean => {
  switch (market) {
    case MarketCode.Sabor:
      return mlsStatus === MlsStatusSabor.Sold;
    case MarketCode.Austin:
      return mlsStatus === MlsStatusAbor.Closed;
    case MarketCode.CTX:
      return mlsStatus === MlsStatusCtx.CompSold;
    case MarketCode.Houston:
      return mlsStatus === MlsStatusHar.Sold;
    case MarketCode.DFW:
      return mlsStatus === MlsStatusDfw.Sold;
    case MarketCode.Amarillo:
      return mlsStatus === MlsStatusAmarillo.Closed;
    default:
      return false;
  }
};

export const isCancelled = (market: MarketCode, mlsStatus: string): boolean => {
  switch (market) {
    case MarketCode.Sabor:
      return mlsStatus === MlsStatusSabor.Cancelled;
    case MarketCode.Austin:
      return mlsStatus === MlsStatusAbor.Cancelled;
    case MarketCode.CTX:
      return mlsStatus === MlsStatusCtx.Withdrawn;
    case MarketCode.Houston:
      return (
        mlsStatus === MlsStatusHar.Terminated ||
        mlsStatus === MlsStatusHar.Expired
      );
    case MarketCode.DFW:
      return mlsStatus === MlsStatusDfw.Cancelled;
    case MarketCode.Amarillo:
      return mlsStatus === MlsStatusAmarillo.Cancelled;
    default:
      return false;
  }
};

export const getMarketQuickCreateListingRules = (
  marketCode: string
): Promise<FormRules | undefined> =>
  new Promise<FormRules | undefined>((resolve) => {
    switch (marketCode) {
      case MarketCode.Austin:
        import("markets/abor").then((module) =>
          resolve(module.quickCreateSaleListingRules)
        );
        break;
      case MarketCode.Sabor:
        import("markets/sabor").then((module) =>
          resolve(module.quickCreateListingRules)
        );
        break;
      case MarketCode.DFW:
        import("markets/dfw").then((module) =>
          resolve(module.quickCreateListingRules)
        );
        break;
      case MarketCode.CTX:
        import("markets/ctx").then((module) =>
          resolve(module.quickCreateListingRules)
        );
        break;
      case MarketCode.Houston:
        import("markets/har").then((module) =>
          resolve(module.quickCreateListingRules)
        );
        break;
      case MarketCode.Amarillo:
        import("markets/amarillo").then((module) =>
          resolve(module.quickCreateListingRules)
        );
        break;
      default:
        resolve(undefined);
    }
  });
