import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules, zipCodeRule } from "@homesusa/form";
import { StreetType, StreetTypeLabel } from "markets/har/enums";
import {
  stateRule,
  streetNameRule,
  streetNumberRule,
  subdivisionRules,
  unitNumberRules
} from "core/rules/common-rules";
import { countyRules, harCityRule } from "../../sections";

export const addressInfoRules: FormRules = {
  county: {
    ...countyRules,
    requiredToSubmit: true
  },
  subdivision: {
    ...subdivisionRules,
    requiredToSubmit: true
  },
  streetNumber: streetNumberRule,
  streetName: streetNameRule,
  state: stateRule,
  zipCode: {
    ...zipCodeRule,
    required: true
  },
  city: {
    ...harCityRule,
    required: true
  },
  streetType: {
    label: "Street Type",
    options: getOptionsFromEnumAndMap(
      Object.values(StreetType),
      StreetTypeLabel
    )
  },
  unitNumber: unitNumberRules
};
