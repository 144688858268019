import { FormRules, zipCodeRule } from "@homesusa/form";
import {
  aborCityRule,
  countyRules,
  propertyRules as commonPropertyRules
} from "../../sections";
import { subdivisionRules } from "core/rules/common-rules";

export const propertyRules: FormRules = {
  ...commonPropertyRules,
  subdivision: subdivisionRules,
  zipCode: zipCodeRule,
  city: {
    ...aborCityRule,
    required: false
  },
  county: countyRules
};
