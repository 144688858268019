import React, { Fragment } from "react";
import {
  Tabs,
  Tab as MuiTab,
  Box,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";

import { AuthCompanyContext } from "@homesusa/auth";
import { useRefresh } from "@homesusa/core";
import { Tab as TabInterface } from "../../interfaces";
import { GreetingMessage } from "../greeting-message.component";
import { useStyles } from "./actions-tab.styles";
import { RouterPath } from "core/enums";

export const ActionsTab = ({
  tabs,
  hideSectionsTab = false
}: {
  tabs: TabInterface[];
  hideSectionsTab?: boolean;
}): JSX.Element => {
  const classes = useStyles();
  const { currentCompany } = React.useContext(AuthCompanyContext);
  const [refresh, setRefresh] = useRefresh();
  const location = useLocation();
  const navigate = useNavigate();

  const selectedTab = React.useMemo(() => {
    if (location.hash && location.hash.length > 1) {
      const hashValue = location.hash.replace("#", "");
      const tabIndex = tabs.findIndex(({ tabId }) => tabId === hashValue);

      return tabIndex > 0 ? tabIndex : 0;
    }
    return 0;
  }, [location]);

  const handleChange = (_: React.SyntheticEvent, newIndex: number): void => {
    const { tabId } = tabs[newIndex];
    const path = !hideSectionsTab ? RouterPath.DashboardAlerts : "";

    navigate(`${path}#${tabId}`);
    setRefresh();
  };

  if (refresh) {
    return <></>;
  }

  return (
    <Fragment>
      <Box sx={classes.root}>
        {!hideSectionsTab && <GreetingMessage />}

        <Box display="flex">
          {!hideSectionsTab && (
            <Tooltip title="Refresh" sx={{ mr: 2 }}>
              <IconButton onClick={setRefresh} aria-label="Refresh">
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tabs
            sx={classes.tabs}
            value={selectedTab}
            onChange={handleChange}
            centered
            TabIndicatorProps={{
              style: { height: "100%", borderRadius: "50px" }
            }}
          >
            {tabs.map((tab, index) => (
              <MuiTab key={index} label={tab.label} sx={classes.tab} />
            ))}
          </Tabs>
        </Box>
      </Box>

      {
        <Box
          sx={{ padding: !hideSectionsTab ? "0 16px 16px 16px" : "32px 0 0 0" }}
        >
          {tabs[selectedTab].node}
        </Box>
      }

      {!hideSectionsTab && !!currentCompany && (
        <Typography variant="subtitle2" align="center">
          We ❤️ {currentCompany.name}
        </Typography>
      )}
    </Fragment>
  );
};
