import { FormRules, flattenRules } from "@homesusa/form";
import { MlsStatus } from "markets/abor/enums";
import { salePropertyRules } from "./sale-property";
import { rulesByStatus } from "./status";
import { listingRules } from "./listing";
import { showingTimeRules } from "core/rules/showing-time-rules";

const rules = {
  ...listingRules,
  saleProperty: salePropertyRules,
  showingTime: showingTimeRules
};

export const saleListingRules: FormRules = flattenRules(rules);

export const saleListingRulesWithStatus = (mlsStatus: MlsStatus): FormRules =>
  flattenRules({
    ...rules,
    statusFieldsInfo: rulesByStatus.get(mlsStatus)
  });
