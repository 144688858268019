import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FieldRulesProps, FormRules } from "@homesusa/form";
import {
  AccessMethod,
  AccessMethodLabel,
  AppointmentChangesNotificationOptions,
  AppointmentChangesNotificationOptionsLabel,
  AppointmentType,
  AppointmentTypeLabel,
  ConfirmAppointmentCaller,
  ConfirmAppointmentCallerLabel
} from "core/enums";

const additionalRestrictionRules: FormRules = {
  notesForApptStaff: {
    label: "Notes for Appt. Staff"
  },
  notesForShowingAgent: {
    label: "Notes for Showing Agent"
  }
};

const hoursOptions = [...[...Array(13)].map((_, i) => i), 24, 48, 72].reduce(
  (a, b) => ({ ...a, [b.toString()]: `${b} hours` }),
  {}
);

const appointmentRestrictionRules: FormRules = {
  allowInspectionsAndWalkThroughs: {
    label: "Allow Inspections and Walk-Throughs"
  },
  allowAppraisals: { label: "Allow Appraisals" },
  leadTime: { label: "Lead Time" },
  requiredTimeHours: {
    label: "Required",
    options: hoursOptions
  },
  suggestedTimeHours: {
    label: "Suggested",
    options: hoursOptions
  }
};

const accessInformationRules: FormRules = {
  accessMethod: {
    label: "Access Method",
    options: getOptionsFromEnumAndMap(
      Object.values(AccessMethod),
      AccessMethodLabel
    )
  },
  location: { label: "Location" },
  serial: { label: "Serial" },
  combination: { label: "Combination" },
  sharingCode: { label: "Sharing Code" },
  cbsCode: { label: "CBS Code" },
  code: { label: "Code" },
  deviceId: { label: "Device ID" },
  provideAlarmDetails: { label: "Provide Alarm Details" },
  alarmDisarmCode: { label: "Alarm Disarm Code" },
  alarmArmCode: { label: "Alarm Arm Code" },
  alarmPasscode: { label: "Alarm Passcode" },
  alarmNotes: { label: "Alarm Notes" }
};

export const showingTimeRules: Record<string, FormRules | FieldRulesProps> = {
  appointmentType: {
    label: "Appointment Type",
    options: getOptionsFromEnumAndMap(
      Object.values(AppointmentType),
      AppointmentTypeLabel
    )
  },
  additionalInstructions: additionalRestrictionRules,
  appointmentRestrictions: appointmentRestrictionRules,
  accessInformation: accessInformationRules
};

export const contactOrderOptions = [...Array(11)]
  .map((_, i) => i)
  .filter((x) => x > 0)
  .reduce((a, b) => ({ ...a, [b.toString()]: `Contact #${b}` }), {});

export const contactFormRules: Record<string, FieldRulesProps> = {
  order: {
    label: "Select Position to Show in ShowingTime Page",
    options: contactOrderOptions
  },
  email: { label: "Email", required: true },
  firstName: { label: "First Name", required: true },
  lastName: { label: "Last Name", required: true },
  officePhone: { label: "Office Phone" },
  mobilePhone: { label: "Mobile Phone" },
  confirmAppointmentsByOfficePhone: {
    label: "Confirm Appointments by Office Phone"
  },
  confirmAppointmentsByMobilePhone: {
    label: "Confirm Appointments by Mobile Phone"
  },
  confirmAppointmentCallerByOfficePhone: {
    label: "Select who Calls to Confirm Appts by Office Phone",
    options: getOptionsFromEnumAndMap(
      Object.values(ConfirmAppointmentCaller),
      ConfirmAppointmentCallerLabel
    )
  },
  confirmAppointmentCallerByMobilePhone: {
    label: "Select who Calls to Confirm Appts by Mobile Phone",
    options: getOptionsFromEnumAndMap(
      Object.values(ConfirmAppointmentCaller),
      ConfirmAppointmentCallerLabel
    )
  },
  notifyAppointmentChangesByOfficePhone: {
    label:
      "Auto Call to Notify Confirmed/Cancelled Appointments by Office Phone"
  },
  notifyAppointmentChangesByMobilePhone: {
    label:
      "Auto Call to Notify Confirmed/Cancelled Appointments by Mobile Phone"
  },
  appointmentChangesNotificationsOptionsOfficePhone: {
    label: "Select Auto Call Notification - Appt Call Type by Office Phone",
    options: getOptionsFromEnumAndMap(
      Object.values(AppointmentChangesNotificationOptions),
      AppointmentChangesNotificationOptionsLabel
    )
  },
  appointmentChangesNotificationsOptionsMobilePhone: {
    label: "Select Auto Call Notification - Appt Call Type by Mobile Phone",
    options: getOptionsFromEnumAndMap(
      Object.values(AppointmentChangesNotificationOptions),
      AppointmentChangesNotificationOptionsLabel
    )
  },
  confirmAppointmentsByText: {
    label: "Confirm Appointments by Text"
  },
  notifyAppointmentsChangesByText: {
    label: "Notify Confirmed/Cancelled Appointments by Text"
  },
  sendOnFYIByText: {
    label: "Send an FYI by Text"
  },
  confirmAppointmentsByEmail: {
    label: "Confirm Appointments by Email"
  },
  notifyAppointmentChangesByEmail: {
    label: "Notify Confirmed/Cancelled Appointments by Email"
  },
  sendOnFYIByEmail: {
    label: "Send an FYI by Email"
  }
};
