import { flattenRules, FormRules } from "@homesusa/form";
import {
  utilitiesRules,
  financialSchoolsRules,
  profileRules,
  propertyRules
} from "./properties";
import { showingRules } from "../sections";
import { showingTimeRules } from "core/rules/showing-time-rules";

export const communityRules: FormRules = flattenRules({
  showing: {
    ...showingRules,
    directions: {
      ...showingRules.directions
    }
  },
  showingTime: showingTimeRules,
  utilities: utilitiesRules,
  property: propertyRules,
  profile: profileRules,
  financialSchools: financialSchoolsRules
});
