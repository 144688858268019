import { FormRules, zipCodeRule } from "@homesusa/form";
import { subdivisionRules } from "core/rules/common-rules";
import {
  saborCityRule,
  countyRules,
  propertyRules as commonPropertyRules
} from "../../sections";

export const propertyRules: FormRules = {
  ...commonPropertyRules,
  subdivision: subdivisionRules,
  zipCode: zipCodeRule,
  city: {
    ...saborCityRule,
    required: true
  },
  county: countyRules
};
