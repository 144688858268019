import {
  Home as HomeIcon,
  List as ListIcon,
  LocationCity as LocationCityIcon,
  AssessmentTwoTone,
  HolidayVillage,
  Leaderboard
} from "@mui/icons-material";
import { MarketCode } from "@homesusa/core";
import { appStateDefault, SidebarItem } from "@homesusa/layout";
import { RouterPath } from "./router-path.enum";

export enum SidebarItemType {
  Dashboard = "dashboard",
  Listings = "listings",
  Profiles = "profiles",
  ListingRequests = "listingRequests",
  Reports = "reports",
  LeadManagement = "leadManagement",
  ReleaseNotes = "releaseNotes"
}
export const sidebarItems: Record<string, SidebarItem> = {
  [SidebarItemType.Dashboard]: {
    title: "Home",
    icon: HomeIcon,
    endpoint: "/"
  },
  [SidebarItemType.Listings]: {
    title: "Listings",
    icon: HolidayVillage,
    items: [
      { title: "Residential", endpoint: RouterPath.ListingSaleGrid },
      {
        title: "Lot",
        endpoint: RouterPath.LotListingGrid,
        filterByMarket: [MarketCode.Austin]
      }
    ]
  },
  [SidebarItemType.Profiles]: {
    title: "Profiles",
    icon: LocationCityIcon,
    items: [
      { title: "Community", endpoint: RouterPath.CommunityGrid },
      { title: "Plan", endpoint: RouterPath.PlanGrid }
    ]
  },
  [SidebarItemType.ListingRequests]: {
    title: "Listing Requests",
    icon: ListIcon,
    items: [
      {
        title: "Residential",
        endpoint: RouterPath.ListingSaleRequestGrid
      },
      {
        title: "Lot",
        endpoint: RouterPath.ListingLotRequestGrid,
        filterByMarket: [MarketCode.Austin]
      }
    ]
  },
  [SidebarItemType.Reports]: {
    title: "Reports",
    icon: AssessmentTwoTone,
    items: [{ title: "Realtor Intel", endpoint: RouterPath.Reports }]
  },
  [SidebarItemType.LeadManagement]: {
    title: "Lead",
    icon: Leaderboard,
    items: [
      {
        title: "Emails",
        endpoint: `${appStateDefault.apps.leadManagement}/lead-emails`
      },
      {
        title: "Phones",
        endpoint: `${appStateDefault.apps.leadManagement}/lead-phones`
      }
    ],
    externalEndpint: true
  }
};
