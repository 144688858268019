import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { latitudeRule, longitudeRule } from "core/rules/common-rules";
import {
  ConstructionStage,
  ConstructionStageLabel,
  LotDescription,
  LotDescriptionLabel
} from "markets/sabor/enums";
import { propertyRules, saborOccupancyRule } from "../../sections";

export const propertyInfoRules: FormRules = {
  ...propertyRules,
  constructionCompletionDate: {
    requiredToSubmit: true,
    label: "Completion Date",
    tooltip: "Completion date where applicable."
  },
  constructionStartYear: {
    requiredToSubmit: true,
    label: "Year Built",
    maxLength: 4
  },
  taxId: { label: "Tax ID #", maxLength: 50 },
  constructionStage: {
    label: "Construction Status",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(ConstructionStage),
      ConstructionStageLabel
    )
  },

  legalDescription: {
    label: "Legal Description",
    maxLength: 60,
    customFieldProps: {
      errorMessages: {
        requiredToSubmit:
          "If Legal Description is Unknonw, Enter Street Address"
      }
    },
    requiredToSubmit: true
  },
  lotDimension: { label: "Lot Dimensions", maxLength: 25 },

  lotSize: {
    label: "Lot Size/Acreage",
    maxLength: 25,
    requiredToSubmit: true,
    tooltip: "For less than one acre use a decimal - Example .25",
    numberFormat: {
      decimalScale: 4
    }
  },

  lotDescription: {
    label: "Lot Description",
    options: getOptionsFromEnumAndMap(
      Object.values(LotDescription),
      LotDescriptionLabel
    ),
    sort: false
  },
  occupancy: saborOccupancyRule,
  updateGeocodes: { label: "Update Geocodes" },
  latitude: latitudeRule,
  longitude: longitudeRule
};
